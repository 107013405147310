import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { IDonor } from '../../../common/payment/models/order.model';
import { IFundraiser, IBasicInfo, ISipCause } from '../../../fundraiser/models/fundraiser.model';
import { ICLientData } from '../../models/client.model';
import { IMainLayoutConfig } from '../../models/main-layout.config';
import { IUser } from '../../models/user.model';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DraftDataI } from '../../models/draft.model';
import { PaymentService } from '../payment/payment.service';
import { filter, pairwise } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { STORY_LANGUAGE } from '../../models/i18n/stories/en';
import { ICampaign } from '../../models';
import { AppLinks, MedibuddyAppLinks } from '../../models/app-links';
import { InsuranceVendors, ISelectedIllness, ISelectedInsurance } from '../../models/insurance.model';
import { SupportedSipCauses } from '../../models/sip-cause.model';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  startFundraiserWidgetStatus = new BehaviorSubject(false);
  draftData = new BehaviorSubject<DraftDataI>(null);
  isFundraiserLeftDialog = false;

  draftObs = new Subject<any>();
  selectedHospital: any;
  purposeSelected = false;
  isFOS = false;
  adminToken = '';
  RAISED = '1100 Crs+';
  DONORS = '72 Lakh+';
  FUNDRAISERS = '3.2 Lakh+';
  PLATFORMFEE = '0%';
  FOLLOWERS = '2.5M +';
  fundraiserCount = '1,50,256';
  /**Call comment card function */
  commentCard = new Subject<any>();
  /**User Information */
  userData = new BehaviorSubject<IUser>(null);
  /**Client machine location info */
  clientLocationData = new BehaviorSubject<ICLientData>(null);

  /**Filter Data for Browse Fundraiser */
  appliedFilters = new BehaviorSubject<any>({ 'category': 'C1', 'type': 'T1', 'location': 'L1' });
  /**Variable to toggle reset on Browse */
  resetFilter = new Subject<any>();
  /**Apply Mobile Filter */
  applyMobileFilter = new Subject<any>();
  /**Counter check to fire b-lazy */
  resetCounter = new BehaviorSubject<boolean>(false);

  // Theme of the website
  theme = new Subject<any>();

  /**Fundraier Object */
  fundraiser: IFundraiser;
  /**Fundraier Object Basic info*/
  basicInfo = {} as IBasicInfo;
  // healthfirst dashboard data 
  hfDashboardData: any = null;

  /**Type of the campaign like story or fundraiser */
  campaignType = '';

  campaignPurpose: any = 'medical';
  showSocialShare = true;
  drawerState = new BehaviorSubject(false);

  /**Header and footer config in main layout */
  mainLayoutConfig = new BehaviorSubject<IMainLayoutConfig>({
    animateHeader: false,
    logoNavigate: true,
    showFooter: true,
    showHeader: true,
    headerTopSticky: false,
    showMenus: true,
    showProfile: true,
    showSearch: true,
    showSecure: false,
    trustedLine: false,
    hideAbandonedCart: false,
    enableDock: false
  });

  openDrawerFromDockState = new BehaviorSubject<boolean | string>(false);
  /**header logo click event */
  logoOnclik = new Subject<any>();
  /**Scroll into view varaible */
  scrollIntoView = new Subject<{ name: string, scroll: boolean }>();
  /**Open search box */
  openHeaderSearch = new Subject<boolean>();
  /**Beneficiary object */
  beneficiary: any;
  /**Comment tab swith */
  commentTabSwitch = new Subject<string>();
  /**Thankyou banner */
  donationDetails = new BehaviorSubject<IDonor>(null);
  /**The payment option set */
  setPaymentOption = new BehaviorSubject<{ card?: boolean, netbanking?: boolean, upi?: boolean, paytm?: boolean }>({});
  /**Show thank you banner */
  showThankYoubanner = new BehaviorSubject<any>(null);
  /**Meta data of the page */
  metaDataOfPage: any;
  /**The order id of currently activated/created order */
  currentOrderId: any;
  /**Name of the current page */
  pageName = '';
  /**Browse fundraiser filter menu click listner */
  showCouponOffer = true;
  isCategoryMenuCliked: any;
  /**Type of the payment dialoge */
  paymentDialogType = new BehaviorSubject<string>('');
  // lead form hidden field config
  leadMailTo = 'leads.ketto@gmail.com';
  leadMailToInfo = 'info@ketto.org';
  leadSubject = 'You received a new lead';
  leadType = 'P - Talk to us (browse fundraisers)';
  // Social links and button
  social = false;
  // Payment options params
  paymentOption = false;
  // Check if phone pe swith
  isPhonePe = false;
  // check if opened in third party apps like paytm, phonepe
  thirdPartyApp = false;
  // Check if phone pe swith
  isPaytmMini = false;
  homeV2 = new BehaviorSubject(false);

  otdpayment = new BehaviorSubject<any>(null);

  /**User last four digits of the card */
  lastOrderInfo = new BehaviorSubject<any>(null);
  /**Last payment mode */
  lastOrderMode = new BehaviorSubject<{ mode: string, value: string, gateway: string }>({ mode: '', value: '', gateway: '' });
  /**Previos visited page url */
  previousPageURL = '';
  qrOpened = false;

  campaignCount: number;
  portfolioCounts = new BehaviorSubject('');
  /**User last four digits of the card */
  globalVar = new ReplaySubject<any>(1);
  selectTipPercentage: any;

  /**Unique Id for truecaller verification */
  req_nonce = '';

  impactData: any;
  subsCount = new BehaviorSubject<any>({});
  private subsCountApiCalled = false;

  /** thank you page url params */
  tyParams: any;
  createCart: any;
  createCartNewUser: any;

  /** Translate Obverable */
  translateLangArray = new BehaviorSubject<any>([]);
  tranlatedLanguage = 'en';
  languageObs = new BehaviorSubject<string>('en');
  medicalbill = [];
  campaignId: any;
  isOrderLoading = false;
  showTimer = false;
  disableUpi = false;
  gtmPageData: any;
  sipActiveOnPayment = new Subject<string>();

  isQuizCompleted = false;
  lastQuizResponse = false;
  // leads mobile number
  contactUsMobNumber = '9930088522';

  paymentOrigin: any;
  typSipAmount: any;
  typSipAmountDefaultINR = 100;
  isFundraiserEnded: any;
  stripeUsToken: any;
  offerActivated = new ReplaySubject<number>(1);
  validCausePM = [4, 7, 59, 103, 150, 156, 159];
  campaign: ICampaign;

  campaignIdForSip: any;
  trucallerRes = new Subject<{ name: string, phone: string, email: string }>();

  /**check for memorial sip */
  sip_cause = new BehaviorSubject<ISipCause>(null);

  previousUrl: any;

  showDock = new Subject<boolean>();

  currencyObj: any;
  onUpiOptionClick = new Subject<{ panelClass: string, name: string, route: string, }>();

  appLink = AppLinks;

  upiTimer = false;

  deviceType: any;
  clickedAnywhere = false;

  loginMethod: string = '';
  loginType: string = '';

  upi_payment_status = new BehaviorSubject<'success' | 'failed' | 'canceled' | ''>('');

  min_sip_amounts = { INR: 100, USD: 10, GBP: 10, EUR: 10, AED: 20, SGD: 30, SAR: 50, CAD: 10, HKD: 10, QAR: 10, AUD: 10, MYR: 10 };
  event_info = { info_1: '', info_2: '', info_3: '', info_4: '' };
  system_event = {
    contribute_initiated: { info_1: '', info_2: '', info_3: '', info_4: '' },
    contribute_cart: { info_1: '', info_2: '', info_3: '', info_4: '' },
    order_created: { info_1: '', info_2: '', info_3: '', info_4: '' }
  }

  insurance_vendors = InsuranceVendors;

  selected_insurance: ISelectedInsurance = null;

  criticalIllnessPlans: {};
  selectedIllness: ISelectedIllness

  insurancePlans = ['care', 'essentials', 'plus'];

  insurance_plans_text = { care: 'Care', plus: 'Plus', essentials: 'Essentials' };

  switch_plan = new BehaviorSubject<string>('care');
  reload_qr_code = new BehaviorSubject<boolean>(false);
  currency: any;
  tickerData = new BehaviorSubject<any>([]);

  non_login_user_data: any;
  doGoodDays = new BehaviorSubject<any>(null);
  stringOnly_Regex = /^[a-zA-Z\s]+$/;
  numberOnlyRegex = /^\d+(\.\d+)?$/;
  name_Pattern_Regex = /^[a-z ,.'-]+$/i;
  email_Pattern_Regex = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
  psw_Pattern_Regex = /^(?=.*[a-z])+(?=.*[A-Z])+(?=.*\d).{8,}$/;
  supported_sip_causes = SupportedSipCauses;
  default_sip_cause = 'medical';

  is_browser: any;
  isIndependanceDay;

  upiTimerOuter = new Subject<boolean>();
  isVariableLogin = false;
  varLoginData: { user: any, userdata: any } = { user: null, userdata: null };
  getCartInsurance = new BehaviorSubject<any>(null);
  countSubsCause;

  showHFgift = false;
  showHFMental = false;
  minTipRequired = 200;
  giftAmount = '₹500';
  hfGiftCouponCode = '';
  hfRedirectUrl = '';
  recommendedVpa = '';
  kettoPremiumPlansList = [];
  activeKettoPremiumPlanData;

  showHfMini = false;
  showBulkHfMini = false;
  hfMiniPlans = { id: environment.health_first_campaign_id, amount: 149 };
  selectedHfMiniPlan: { plan_id: number, amount: number } = null;

  medibuddyAppLinks = MedibuddyAppLinks;

  couponDetails: { coupon_code?: string, discount_amount?: number, payable_amount?: number } = null;

  userPhoneExt = '';
  userPhoneNumber = '';

  donateMultiPatient: boolean = false;

  showKCart = false;
  selectedKCart: any[] = null;

  kCartDockData: { image?: string, title?: string, amount?: number, currency_symbol?: string } = null;
  resetSelectedKCart = new BehaviorSubject<any>(null);
  kCartCurrencyChange = new BehaviorSubject<{currency?: string, preSelect?: boolean}>(null);

  kKartSelectionChange = new BehaviorSubject<{amount?: number, currency?: string}>(null);
  paymentFormAction = new BehaviorSubject<'open' | 'closed' | ''>('');

  showBulkDonationForMiniStory = /^(telecalling_external_otd|telecalling_internal_otd)$/i;

  hfSurveyStatus: 'opened'|'done'|'closed'|'' = '';
  hfHomeClick = new BehaviorSubject<boolean>(null);
  dummyEmailExtn = '@dummyketto.org';
  isDummyEmail = false;

  mandateLoginCheck = true;

  selectedFamilyInsurance: any;

  oneTapPayError = new BehaviorSubject<'invalid'|'limit_exceed'|''>(null);

  selectedOtdSipAmt = 0;

  afterLoginRedirectsHit = new Subject<boolean>();
  bbpsPusherRes = new Subject<any>();
  isBbpsPopupOpen = false;
  showStoryOnFundraiser = false;

  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private paymentService: PaymentService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.is_browser = isPlatformBrowser(this.platformId);
    if (this.is_browser) {
      this.router.events.pipe(filter((evt: any) => evt instanceof NavigationEnd), pairwise()).subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = location.origin + events[0].urlAfterRedirects;
      });
      this.isPhonePe = window.navigator.userAgent.match('phonepe-webview') ? true : false;
      const paytmContainer = environment.production ? 'AppContainer' : 'PhoenixContainer';
      this.isPaytmMini = window.navigator.userAgent.match(paytmContainer) ? true : false;
      if (this.isPhonePe) {
        this.thirdPartyApp = true;
      }
      if (this.isPaytmMini) {
        this.thirdPartyApp = true;
      }
      if (this.thirdPartyApp) {
        this.social = false;
        this.paymentOption = false;
      } else {
        this.actRoute.queryParams.subscribe((params) => {
          this.social = +params['social'] === 0 ? false : true;
          this.paymentOption = +params['payops'] === 0 ? false : true;
          // this.isPhonePe = params['utm_source'] === 'phonepe' ? true : false;
        });
      }
    }
  }

  setNewMinSipAmounts(obj: any) {
    this.min_sip_amounts = { ...this.min_sip_amounts, ...obj };
  }

  /** Send last order value */
  setLastOrderValues(order: any) {
    this.lastOrderMode.next({ mode: order.payment_mode, value: order.payment_mode_value, gateway: order.payment_gateway });
    if (order.checksum) {
      let lastOrder = Object.assign(order, {
        mode_value: order.payment_mode_value,
        mode: order.payment_mode,
        gateway: order.payment_gateway
      });
      if (order.payment_mode === 'UPI') {
        lastOrder.upi_id = order.checksum;
      }
      if (order.payment_mode === 'UPI' && order.payment_gateway === 'camspay') {
        lastOrder = Object.assign(lastOrder, JSON.parse(order.checksum));
      }
      if (order.payment_gateway.match('stripe') && !this.pageName.match('sip_welcome')) {
        lastOrder = Object.assign(lastOrder, JSON.parse(order.checksum));
        this.stripeUsToken = lastOrder.token_1;
      }
      if (order?.donor?.full_name) {
        lastOrder.donor_name = order.donor.full_name.trim();
      }
      this.lastOrderInfo.next(lastOrder);
    }
  }

  /**Listen to the global variable and whenever
   * just need to call it like this
   * globalVarSetter.myKey = "its value";
   */
  listenToGlabalJsVar() {
    if (this.is_browser) {
      try {
        (<any>window).globalVarSetter = new Proxy({}, {
          set: (target: any, key, value) => {
            target[key] = value;
            this.globalVar.next(target);
            return true;
          }
        });
      } catch (e) { }
    }
  }

  async loadTranslationJson() {
    if (typeof window !== 'undefined') {
      const translationJson = await import(`../../../../assets/i18n/stories/${this.tranlatedLanguage}.json`);
      return translationJson.default;
    } else {
      return STORY_LANGUAGE;
    }
  }

  getTranslatedJson(keyString: string) {
    if (keyString) {
      return this.loadTranslationJson().then(translationJson => this.getObjFromStringNotation(translationJson, keyString));
    }
  }
  getTranslatedJsonObs(keyString: string) {
    const subject = new BehaviorSubject<any>('');
    this.languageObs.subscribe(async lang => {
      if (lang) {
        const translationJson = await this.loadTranslationJson();
        subject.next(this.getObjFromStringNotation(translationJson, keyString));
      }
      return lang;
    });
    return subject;
  }

  private getObjFromStringNotation(obj: any, keyString: string) {
    const keys = keyString?.split('.') || [];
    keys.forEach(key => {
      if (key in obj) {
        obj = obj[key];
      } else {
        return;
      }
    });
    return obj;
  }

  getStartFundraiserWidgetStatus() {
    return this.startFundraiserWidgetStatus.asObservable();
  }

  setStartFundraiserWidgetStatus(value: boolean) {
    this.startFundraiserWidgetStatus.next(value);
  }

  getSubsCount(cause?: string) {
    if (!this.subsCountApiCalled || cause) {
      this.subsCountApiCalled = true;
      let query = '';
      if (cause) { query = '?cause=' + cause; }
      this.paymentService.getSubscriptions(query).subscribe((res: any) => {
        this.countSubsCause = query.split('=')[1];
        this.impactData = res.data.data;
        this.subsCount.next(res.data.data);
      });
    }
  }
}
